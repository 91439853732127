import { v4 as uuidv4 } from 'uuid';
import supp from '../assets/blogs/supp1.webp';
import fiems from '../assets/blogs/fiems.webp';
import vcp from '../assets/blogs/vcp.webp';
import saprul from '../assets/blogs/saprul.webp';
import malnutrition from '../assets/blogs/malnutrition.webp';
import crisis from '../assets/blogs/crisis.webp';
import vac from '../assets/blogs/vac.webp';
import veg from '../assets/blogs/veg.webp';
import rose from '../assets/blogs/elkanis-4.webp';
import silicon from '../assets/blogs/silicon.webp';
import maize from '../assets/blogs/loading_maize.webp';
import chairman from '../assets/blogs/prof_ajah.png';
import radio from '../assets/blogs/radio.webp';
import radioDisc from '../assets/blogs/radio_disc.webp';
import image from '../assets/blogs/image.png';
import chairAgro from '../assets/blogs/chairman.jpeg';
import elkanis from '../assets/blogs/elkanis.png';

const blogData = [
  {
    id: uuidv4(),
    day: '22',
    month: 'Aug',
    year: '2024',
    title: 'Radio Program Recap: Empowering Livestock Farmers with El-kanis Heavy Weight Supplements',
    img: elkanis,
    content: [
      "Today, we had the privilege of discussing the critical role of El-kanis Heavy Weight livestock supplements on air, highlighting how we're helping poultry farmers improve their operations and boost profitability.",
      'Part of the discussion centered on how the supplement can lower Feed Conversion Ratio (FCR): We explored how our supplements are designed to optimize the FCR, meaning less feed is required for the same amount of weight gain in poultry. This efficiency is a game-changer for farmers, significantly cutting down feed costs—an expense that traditionally accounts for up to 90% of operational costs.',
      "Balanced Nutrition for Better Growth: The importance of balanced nutrition can't be overstated. Our supplements ensure poultry receives all the essential nutrients for faster and healthier growth, reducing the time to market and allowing farmers to maximize their outputs.",
      "Empowering Women and Youth: A major focus of our discussion was how El-kanis Heavy Weight supplements support women and youth in livestock farming. By lowering feed costs and increasing profitability, we're helping these groups overcome some of the biggest challenges in the industry, keeping them in business and thriving.",
      "Driving Sustainable Agriculture: Our mission at El-kanis Agro is to create a sustainable and profitable agricultural sector. Through initiatives like these supplements, we're not just enhancing farm productivity; we're also contributing to food security and job creation across Nigeria.",
      "Thank you to all the poultry farmers and agro inputs suppliers who patronize our products, and a special thanks to the wonderful El-kanisAgro team members for blending great supplements. Together, we're making strides towards a more prosperous future for our farmers.",
      "Let's keep the conversation going—feel free to connect and reach out if you're interested in learning more about how El-kanis is transforming agriculture.",
    ],
  },
  {
    id: uuidv4(),
    day: '27',
    month: 'Oct',
    year: '2023',
    title: 'Growing Together: Collaborating for Agricultural Advancement in Akwa Ibom State',
    img: chairAgro,
    content: [
      "The Managing Director of EL-kanis, Mr Ekanikpong Ben had the privilege of meeting with the dynamic Chairman, House Committee on Agriculture and Rural development, Akwa ibom State House of Assembly, Honorable Ubong Attah, to discuss the future of our state's agricultural landscape. Our discussion revolved around strategies to foster growth in the agriculture sector, and it was an inspiring conversation indeed!",
      '<h2>Key Takeaways:</h2>',
      '<h3>Empowering Rural Farmers:</h3>',
      'We explored various initiatives aimed at enhancing the lives of our rural farmers. From comprehensive training programs to continuous monitoring and facilitating better market access, our commitment to improving their livelihood is unwavering.',
      '<h3>Tackling Challenges Head-On:</h3>',
      'We delved into the significant challenges crippling the agricultural industry, including the state of our roads, the issue of illegal checkpoints, and post-harvest losses. Recognizing these problems is the first step towards solving them!',
      '<h3>Collaboration is Key:</h3>',
      "To translate these discussions into action, we are eager to join forces with development agencies and other stakeholders within the state. Together, we can drive meaningful change and revolutionize Akwa Ibom's agriculture sector.<br>",
      "Honorable Ubong Attah's commitment to this vision is incredibly encouraging. He assured us of the State House of Assembly's unwavering support for agricultural entrepreneurs. They have already approved a substantial agricultural budget for the state, reflecting a clear commitment to boosting food production.",
      "The future of agriculture in Akwa Ibom State is brimming with potential, and we are enthusiastic about the journey ahead. Let's work together to turn these aspirations into reality, making our state a shining example of agricultural excellence!",
    ],
  },
  {
    id: uuidv4(),
    day: '17',
    month: 'Oct',
    year: '2023',
    title: "El-Kanis Radio Program Report: Unveiling South-South's Agri-Crisis - Tackling Soaked Stubble with Dry Innovation",
    img: image,
    content: [
      'The El-Kanis Radio Program, a vanguard of information dissemination, brought to light a dire predicament facing our agricultural community in the South-South region of Nigeria. In our latest series, we plumbed the depths of a pressing issue: the deluge of rain and its ruinous impact on our farming and food processing activities.',
      "We had the privilege of participating in the El-Kanis Radio Program recently, where the discussion revolved around the significant challenges faced by farmers and food processors in the South-South region due to heavy rainfall. This vital conversation illuminated the pressing issue of drying in agriculture and its cascading effects on the region's food production.",
      'Heavy Rainfall Dilemma: The South-South region is blessed with abundant rainfall, which is crucial for farming. However, excessive rain can also pose a challenge. Prolonged and heavy downpours often lead to difficulties in drying agricultural products such as grains, fruits, and vegetables, making it a pressing concern for local farmers and processors.',
      '<h3>Mechanical Drying System Costliness:</h3>',
      "The discussion highlighted that many farmers and food processors have resorted to using mechanical drying systems, but these come with a hefty price tag. The cost of acquiring and maintaining such equipment can significantly impact the overall cost of food production in the region. This not only affects farmers' profitability but also the affordability of food for consumers.",
      '<h3>Seeking Solutions Together:</h3>',
      "It's evident that the challenges of drying in the South-South region are multifaceted and require a collaborative effort to find sustainable solutions. These challenges can drive innovation and investment in more efficient and cost-effective drying methods, helping farmers and processors thrive.",
      "As we navigate these challenges, it's crucial that the government, industry stakeholders, and the private sector come together to support initiatives that provide practical solutions for the South-South region's agricultural community. This way, we can not only enhance food production but also ensure its affordability, ultimately benefiting the entire region.",
    ],
  },
  {
    id: uuidv4(),
    day: '22',
    month: 'Sept',
    year: '2023',
    title: 'Navigating Poultry Industry Challenges: Insights from our Radio Dialogue',
    img: radioDisc,
    content: [
      'This week, we had the privilege of participating in a lively discussion on a local radio program dedicated to enlightening farmers about the challenges facing the poultry industry. It was an eye-opening experience, and we were thrilled to share insights into how El-Kanis Livestock Supplement is making a significant impact in supporting farmers to stay profitable and overcome these challenges.',
      'The poultry industry plays a pivotal role in our economy, providing a vital source of nutrition and livelihood for countless individuals. However, it faces its fair share of challenges, including disease outbreaks, fluctuating feed prices, and changing consumer preferences. During the radio program, we delved deep into these issues and explored potential solutions.',
      "One shining beacon of hope that emerged from our discussion was the El-Kanis Livestock Supplement. This innovative supplement has been a game-changer for poultry farmers, helping them tackle some of the industry's most pressing problems:",
      '<h3>Disease Management:</h3>',
      'El-Kanis Livestock Supplement is formulated to boost the immunity of poultry birds, reducing the risk of diseases and minimizing the need for antibiotics.',
      '<h3>Nutrition Optimization:</h3>',
      'The supplement is designed to enhance the nutritional content of poultry feed, ensuring that birds receive the essential nutrients they need for healthy growth and egg production.',
      '<h3>Profitability:</h3>',
      "By improving bird health and productivity, El-Kanis Livestock Supplement has been instrumental in increasing farmers' profitability, even in the face of market volatility.",
      "It's truly heartening to witness the positive impact this product has had on poultry farming communities. Farmers have shared their success stories of healthier flocks, increased egg production, and improved income levels, all thanks to El-Kanis.",
      "As we move forward, it's clear that innovation and collaboration are key to addressing the challenges facing the poultry industry. I want to express my gratitude to the radio program for providing a platform for these crucial discussions and to El-Kanis Livestock Supplement for their commitment to supporting our farmers.",
      "If you're a poultry farmer or involved in the industry, I encourage you to explore the benefits of El-Kanis Livestock Supplement. It's not just a product; it's a testament to the power of innovation in agriculture and the potential to transform challenges into opportunities.",
      "Let's continue working together to ensure a thriving and sustainable poultry industry for generations to come!",
    ],
  },
  {
    id: uuidv4(),
    day: '31',
    month: 'Aug',
    year: '2023',
    title: 'El-kanis Empowers Over 500,000 Farmers via Local Radio Training and Toll-Free Support',
    img: radio,
    content: [
      'In a remarkable effort to boost rural farming practices, <a href="https://ng.linkedin.com/company/elkanis-and-partners" target=”_blank”>El-kanis</a> is revolutionizing the agricultural landscape by training more than 500,000 farmers through a unique approach. Leveraging the power of local dialect on the airwaves, the program airs on Tang Sio 102.7FM, Uyo, Akwa Ibom State, Nigeria every Wednesday from 6:00pm to 6:30pm, reaching communities that may have been overlooked.',
      '<h3>The Heart of the Initiative</h3>',
      'Using the familiar voice of Tang Sio 102.7FM, the program provides agricultural training in local dialects, effectively connecting with farmers on a personal and relatable level. This exceptional initiative is augmented by a toll-free hotline, facilitating agricultural extension services for farmers seeking advice and guidance. With over 2,000 calls daily, the impact is undeniable.',
      '<h3>Transforming Farming Practices</h3>',
      'The El-kanis program is ushering in a new era of good agronomic practices. By providing tailored guidance to farmers on topics such as crop management, pest control, and sustainable techniques, the program is empowering rural communities to enhance their yields and livelihoods. The local dialect ensures that the information is easily understood, effectively bridging the gap between knowledge and application.',
      '<h3>Cultivating Prosperity and Networks</h3>',
      'A testament to its success, farmers engaged with the El-kanis program are proudly adopting the title of "El-kanis Farmers Network." This close-knit community of agricultural enthusiasts is cultivating a range of produce, knowing that El-kanis is ready to off-take for further processing. This seamless integration between farming and processing ensures that the gap between producers and market access is minimized.',
      '<h3>Advancing Agriculture, One Broadcast at a Time</h3>',
      "El-kanis' innovative approach is not only transforming rural farming practices but also contributing to the larger goal of sustainable agricultural development. By harnessing the power of radio and local dialects, the program is creating a ripple effect of positive change, improving lives, and strengthening communities.",
      'In a world where technology and tradition intertwine, El-kanis is a beacon of progress, guiding farmers toward prosperity and sustainable practices. Join us every Wednesday between 6:00 and 6:30 pm on Tang Sio 102.7FM, and witness the extraordinary journey of rural empowerment and growth.',
    ],
  },
  {
    id: uuidv4(),
    day: '09',
    month: 'Aug',
    year: '2023',
    title: 'Chairman of El-kanis & Partners Attains Professorship at the University of Uyo: Celebrating a Milestone Achievement!',
    img: chairman,
    content: [
      'On behalf of <a href="https://ng.linkedin.com/company/elkanis-and-partners" target=”_blank”>El-kanis & Partners</a>, we are absolutely delighted to extend our heartfelt congratulations to our esteemed Chairman, <a href="https://ng.linkedin.com/in/richard-oko-ajah-phd-11085122" target=”_blank”>Richard Oko Ajah, PhD</a> on his recent elevation to the distinguished position of Professor at the prestigious University of Uyo! Professor Ajah\'s extraordinary dedication, visionary leadership, and exceptional contributions to academia and the field of human development have earned him this well-deserved honor.',
      'His passion for knowledge and commitment to excellence have not only shaped the success of <a href="https://ng.linkedin.com/company/elkanis-and-partners" target=”_blank”>El-kanis & Partners</a> but have also inspired countless individuals along the way. As our Chairman, Professor Ajah has been the guiding light, leading our team with brilliance and wisdom. His unwavering support and mentorship have been instrumental in our growth and achievements as a firm. Under his visionary leadership, EL-kanis and Partners has reached new heights and continues to make a significant impact in the industry.',
      'This elevation to the esteemed position of Professor reflects not only his academic prowess but also his commitment to nurturing talent and fostering a culture of innovation within our organization. His accomplishments serve as an inspiration to all of us, motivating us to strive for excellence in everything we do. We are immensely proud to have Professor Richard Ajah as our Chairman and leader. His passion for education, research, and community development continues to drive positive change both within the academic realm and beyond.',
      "Join us in congratulating Professor Ajah on this momentous achievement and wishing him continued success on his academic journey. Together, let's celebrate this milestone and look forward to even greater accomplishments in the future!",
    ],
  },
  {
    id: uuidv4(),
    day: '24',
    month: 'Jul',
    year: '2023',
    title: 'El-kanis: Building Capacity and Knowledge for 10,000 Rice and Maize Farmers in Cross River State and Ebonyi State through SAPRUL Project',
    img: maize,
    content: [
      'El-kanis, as a key stakeholder in sustainable food and agriculture systems, has taken significant strides in empowering 10,000 smallholder farmers in Cross River State and Ebonyi State, Nigeria. Through the SAPRUL (Sustainable Agricultural Program for Rural Livelihoods) project, El-kanis has been at the forefront of knowledge sharing and capacity development initiatives. El-kanis recognized the importance of including all stakeholders, including youth, rural women, men, and their cooperatives, as full partners in problem identification, research, and advisory services. This participatory approach fostered a sense of ownership and empowerment among the farmers and rural dwellers.',
      "One of the key components of the project was the establishment of pluralistic extension systems involving multiple actors, such as the public sector, private agents, civil society organizations, and non-governmental organizations. El-kanis collaborated with these entities, ensuring that knowledge was delivered to the farmers based on each organization's expertise. This collaborative effort enriched the training programs, covering a wide range of topics essential for sustainable farming practices.",
      '<h3>Farmer-Centric Training Programs</h3>',
      'El-kanis, in collaboration with local partners and farmer organizations, designed comprehensive training programs for rice and maize farmers. These farmer-centric programs focus on enhancing technical knowledge, promoting sustainable practices, and improving farm management skills. Smallholder rice and maize farmers benefited from practical farm management skills, including crop planning, soil health management, water efficiency, pest and disease control, post-harvest handling, financial management, market access, and climate-smart practices. Equipping farmers with these skills enhances productivity, reduces losses, and improves their overall livelihoods. Knowledge of farm mechanization, entrepreneurship, and value addition allows farmers to explore new opportunities and ensure sustainable growth in the agricultural sector.',
      "Take the case of Chief Damian Egbata, a rice farmer from Ekureku, Cross River State. Through El-kanis' farmer field schools, he learned about innovative practices such as System of Rice Intensification (SRI) and improved water management. System of Rice Intensification (SRI) is a sustainable rice cultivation method that emphasizes transplanting young seedlings, wider spacing, reduced water usage, and organic matter incorporation. SRI offers benefits such as higher yields, water efficiency, reduced input costs, climate resilience, and lower greenhouse gas emissions. This innovative approach promotes sustainable agricultural practices and contributes to food security and environmental protection. Armed with this knowledge, Chief Damian increased his rice yield significantly, witnessing a 30% rise in productivity compared to previous years.",
      '<h3>Market-Oriented Approach</h3>',
      'El-kanis recognizes the importance of connecting farmers to modern value chains. The training programs emphasize business-oriented approaches, imparting skills not only in production but also in post-harvest handling, storage, and marketing. By understanding market demands and quality standards, smallholder farmers are better equipped to produce rice and maize that meet market requirements.',
      "Mrs Grace Uche, a maize farmer from Ebonyi State, underwent training on value chain management. El-kanis helped her establish links with Spertant Processing Mill Nigeria Limited, a livestock feed producer who required high-quality maize. As a result, Grace's maize yield improved, and she now supplies her produce to Spertant processing mills, contributing to the company's value chain while securing a stable market for her produce.",
      '<h3>Social Inclusiveness and Gender Sensitivity</h3>',
      'El-kanis places great emphasis on engaging women and youth in the training programs. The company ensures that women farmers actively participate in decision-making and capacity-building initiatives. Moreover, youth are provided with opportunities to explore sustainable socio-economic entrepreneurship in agriculture and related industries.',
      "Miss Mary Okon, a young farmer from Cross River State, benefited from El-kanis' training on sustainable entrepreneurship. With the company's support, she started a small agribusiness, processing maize into various products. Not only did this create new employment opportunities for other youth in the community, but it also generated a steady supply of raw materials for El-kanis' processing mills.",
      "El-kanis' commitment to building the capacity and knowledge of 10,000 rice and maize farmers in Cross River State and Ebonyi State through the SAPRUL project has yielded remarkable results. The shift towards farmer-driven, market-oriented approaches has empowered smallholders to thrive in the agricultural sector. Through training programs, farmers have increased their yields, enhanced product quality, and successfully integrated into modern value chains. Furthermore, El-kanis' focus on social inclusiveness has paved the way for women and youth to actively participate in sustainable agricultural practices and entrepreneurship. El-kanis' initiatives have not only transformed the livelihoods of smallholder farmers but have also contributed to the development of greener and more resilient food systems in the region.",
    ],
  },
  {
    id: uuidv4(),
    day: '04',
    month: 'Jul',
    year: '2023',
    title: 'UNLOCKING OPPORTUNITIES: EL-KANIS EMPOWERS SMALLHOLDER FARMERS IN RICE AND LIVESTOCK TO THRIVE IN THE MARKET',
    img: rose,
    content: [
      "El-kanis, in partnership with the West Africa Trade and Investment Hub is dedicated to empowering smallholder farmers involved in rice, maize, and livestock production by connecting them to markets. The company's commitment to these agricultural sectors is evident in the targeted support it provides to smallholders in these value chains.",
      "Mrs Rose Archibong relies on her rice and livestock production for her family's livelihood. However, she faces challenges in connecting her products to the market and obtaining fair prices for her hard work. This is where El-kanis steps in to empower farmers like Mrs Rose and transform their lives.",
      'Mrs Rose joined a local cooperative that partners with El-kanis. Through this collaboration, Mrs Rose gains access to a wide network of buyers, processors, and retailers in the rice and livestock sectors. El-kanis serves as the bridge, connecting Mrs Rose and other smallholder farmers to these market actors who are eager to purchase their products.',
      "To ensure Mrs Rose's success, El-kanis provides her with specialized guidance. Mrs Rose learns about the latest farming techniques, including best practices for growing rice and improving livestock management. She receives training on post-harvest handling to minimize losses and maintain the quality of her produce. Armed with this knowledge, Mrs Rose maximizes the value-added potential of her crops and livestock, making her products more attractive to buyers.",
      'Furthermore, El-kanis supports Mrs Rose in obtaining certifications and complying with quality standards necessary for market access. Mrs Rose learns about food safety regulations and good agricultural practices, ensuring her products meet the requirements of discerning buyers. With the guidance of El-kanis, Mrs Rose gained access to premium rice markets that value certified and high-quality rice produce.',
      '<h3>How Market Information Support Famers’ Success</h3>',
      'El-kanis recognizes the power of collective action and strengthens farmer organizations like the cooperative Mrs Rose is part of. Through training programs, Mrs Rose and her fellow farmers learn market-oriented strategies, negotiation skills, and collective marketing techniques. As a united group, they have greater bargaining power and can secure better prices and favorable market conditions for their rice and livestock.',
      "As a result of El-kanis' support, Mrs Rose experiences a transformation in her farming business. She now has a direct link to buyers who appreciate her hard work and pay fair prices for her products. With the increased income from her sales, Mrs Rose can invest in her farm, provide better education for her children, and improve the overall well-being of her family.",
      'El-kanis continues to empower smallholder farmers like Mrs Rose, making a lasting impact on the agricultural sector in Nigeria. By facilitating market access, providing practical guidance, sharing real-time market information, supporting certification, and strengthening farmer organizations, El-kanis enables smallholders to thrive in rice and livestock production.',
      'Together, they built a more sustainable and prosperous future for smallholder farmers and their communities.',
    ],
  },
  {
    id: uuidv4(),
    day: '03',
    month: 'Mar',
    year: '2023',
    title: "SUPPORTING NIGERIA'S POULTRY SECTOR WITH EL-KANIS WELL-BLENDED SUPPLEMENTS",
    img: supp,
    content: [
      'For the past 3 years, El-kanis & Partners has been at the forefront of organic livestock supplement and additive production, working with veterinary doctors and animal scientists to develop nutritional supplements for livestock animals, mostly poultry, goat sheep, and pigs.',
      'The products such as El-kanis Heavy Weight, El-kanis Stopper Solution and El-kanis Optimum Booster are added to the feed of livestock to improve their health and performance. These products contain vitamins, minerals, probiotics, enzymes.',
      'They are designed and developed to help animals absorb nutrients more efficiently, improve digestion, and boost immunity. They also help reduce stress and improve overall health.',
      'So far, over 18,000 livestock farmers rely on these supplements and additive which is providing thier animals with the necessary nutrients they need to stay healthy and productive.',
      'The production process typically involves mixing various organic ingredients gotten during our rice processing and other edibles together in a specific order to create a balance and nutritious supplement.',
    ],
  },
  {
    id: uuidv4(),
    day: '13',
    month: 'Feb',
    year: '2023',
    title: 'HOW FARM-LEVEL DATA SUPPORTS FOOD SECURITY IN NIGERIA – LESSONS FROM FIEMS',
    img: fiems,
    content: [
      'Although it is widely recognized that sound decisions are based on good information and data, in many countries, particularly low and lower middle-income countries, the availability of timely and reliable rural, agricultural and food security statistics is largely lacking.',
      'Addressing the gap in quality data is essential to monitor progress and understand where Nigeria stands in achieving its access to food security. EL-kanis and Partners is implementing several projects that are multi-layered in approach to improve food security for Nigerians. In the same vein, projects and campaigns are being implemented by EL-kanis and Partners which works with 325,000 farmers in Cross River State, Akwa Ibom State, Ebonyi State, Kaduna State, Benue State and Yobe State to collect and monitor data related to rice, maize, cassava and livestock production. However, the success of these projects rests largely upon strengthening data collection and statistical capacity-development, including analysis monitoring data from these States and to conduct the data documentation with Farmers Information and Enterprise Management System (FIEMS) which is being deployed by over 85 Extension Agents working in the field.',
      'FIEMS has over 300,000 data base of smallholder farmers collected through enumeration and profiling, with potential to transforms the way Agriculture Financiers, Donors, AgriFood Companies, Government, Insurance Companies, Input Suppliers and relevant Stakeholders engage with smallholder farmers, which also provides a comprehensive set of end-to-end services for Agricultural sector. El-kanis is helping clients maintain competitiveness, upgrade sanitary and food safety standards, and expand market access.',
      'In recent years, El-kanis and Partners has been involved in strengthening national data systems through technical assistance</a>, methodological innovation and research, and by supporting national capacity to collect, process, analyze, and use agricultural data, which has supported the process of laying the groundwork for informed decision making, setting standards for improved data-driven policies around food security and nutrition, and strengthening effective monitoring via FIEMS.',
      'El-kanis FIEMS platform is providing real-time information from rural farmers to Governments and Development Agencies and is supporting in: (1) Identifying barriers impeding quality data collection, analysis, and use in decision-making. (2) Identifying specific high priority gaps in data production and analysis. (3) Highlighting the benefits of using data and the opportunity costs of not using data for food and agricultural decisions. (4) Illustrating initiatives that encourage evidence-based decisions in agriculture and food safety across the public, private, and academic sectors as well as approaches that have not worked. (5) Providing insights into how to ensure data collection and its utilization give voice to the people most affected by policies stemming from that data, including farmers and other food producers.',
      'As interest groups desires to learn more about the characteristic that determine the success of FIEMS they discovered it could: (1) Fully review the rich monitoring farm-level data collected in Nigeria farms to uncover important research questions that can improve food production. (2) Work closely with Government and food industry players and development agencies in the interpretation of findings to formulate recommendations on program actions, improvements, and lessons learned, as appropriate. (3) Work closely with Government and food industry players and development agencies in the interpretation of findings to formulate recommendations on program actions, improvements, and lessons learned, as appropriate</li></ul>',
      'For more than 9 years, El-kanis food programmes has provided high-quality professional services to help more than 20 companies including Small and Medium Scale Enterprises (SMSE) apply national food production and adapt sustainable business models. EL-kanis programs include food production assessments, staff training, and guidance in obtaining certification, Research and development for food safety and security, Data collection and analysis tools development for food security and nutrition.',
      'Our impacts have improved food production and is helping clients meet regional and export market requirements, attract investment, realize cost savings, and strengthen brands. We work with both national and international brands such as National Agency for Food and Drugs Administration and Control (NAFDAC), GIZ, USAID, HarvestPlus, Deloitte, United Nations, Food and Agriculture Organization of the United Nations, Global Open Data for Nutrition (GODAN) Etc.',
      'El-kanis Competency and Track Records',
      "El-kanis has made agribusiness a priority because of the potential of agribusiness for broad development impacts and an especially strong role in poverty reduction.  In 2020 El-kanis developed A data-driven platform for State Governments in Nigeria - a digital farmers' profiling platform that aggregate series of farm-level and farmers’ data to ease the delivery of added- value services.",
    ],
  },
  {
    id: uuidv4(),
    day: '18',
    month: 'Dec',
    year: '2022',
    title: 'EL-KANIS JOINS VILLAGE CAPITAL PROJECT',
    img: vcp,
    content: [
      'El-kanis and Partners has been selected by Village Capital for review of our interventions and provision of training and access to Village Capital’s Abaca App Supporter Package, which includes access to a customized ESO diagnostic tool to track performance and personalized support from Village Capital team.',
      'Having scale through over 180 Enterprise Support Organizations (ESO) Applications from 30 countries in the Sub Sahara Africa region, El-kanis made one of the 20 selected organization to reach the final cohort.',
      'For over 12 years and running, EL-kanis has made a footprint in rural communities in Nigeria, by harnessing their potentials for growth and profitability. In 2020, El-kanis trained over 3000 beneficiaries through the Agribusiness Enterprise Development Program (AEDP), a training program sponsored by the Akwa Ibom Sate Government of Nigeria which aim was to strengthen business linkages among producers, processors and aggregators, while reinforcing  business network through  access to knowledge, technology, land and markets.',
      'For more than 9 years, El-kanis food programmes has provided high-quality professional services to help more than 20 companies including Small and Medium Scale Enterprises (SMSE) apply national food production and adapt sustainable business models. EL-kanis programs include food production assessments, staff training, and guidance in obtaining certification, Research and development for food safety and security, Data collection and analysis tools development for food security and nutrition.',
      'Since then, El-kanis has worked with communities to deliver improved variety seeds, training and tools needed for optimum productivity of smallholder farmers. These resources supports more than 10,000 smallholder farmers to produce quality grains that feeds El-kanis processing mills and suppliers, while ensuring that local and regional agricultural markets are informed by competitiveness and trust.',
      'The motivation and skills needed by rural smallholder farmers to move from subsistence farming to farming as a business already exist within themselves and their communities, and our activities build resilience and trust between communities, governments, and the private sector.',
      '<h3>Fostering Partnerships for Impact</h3>',
      'In 2023, we would be fostering several partnerships with development agencies, the private sector, governments, institutions and organizations to analyze and publish impact reports that measures the value generated for over 100,000 rural smallholder farmers and their households from these joint efforts. The results will be published quarterly and is expected to highlight areas of intervention that could help build their resilient and growth, while supporting our efficiency and profitability.',
    ],
  },
  {
    id: uuidv4(),
    day: '16',
    month: 'Nov',
    year: '2022',
    title: 'SAPRUL PROJECT SUPPORT FARMERS FOR INCREASE PRODUCTIVITY',
    img: saprul,
    content: [
      'In partnership with West Africa Trade and Investment Hub, USAID Feed the Future Zenith Bank and Sterling Bank, El-kanis and  Partners is implementing Sustainable Agriculture Programme for Rural Livelihood(SAPRuL), project to support farmers increase productivity, yield and market.',
      'The project targets farmers in Cross River and Ebonyi States comprising youths and women who are receiving inputs, training and other supports aimed at improving their income.',
      'The productivity of maize and rice farmers is significantly hampered by, poor weather information dissemination, nutrient deficiencies, pest damage, fertilizer needs, plant diseases, limited access to product off-take and flood, however, SAPRuL’s entry point is to deploy solutions such as Farmers Information and Enterprise Management System (FIEMS) Platform to upscale the average yield of maize in the project area from an average of 1.8 metric tons per hectare to 3.6 metric tons per hectare and yield of rice from an average of 2 metric tons per hectare to 4 metric tons per hectare.',
      'FIEMS is a proprietory Application developed by EL-kanis to  support online and offline data collection, facilitate record keeping, and generate easily shareable reports for farming supervisors. Registration and case sharing with FIEMS enable tracking across visits, facilitating continuum of personalized training according to crop, farm size, & technique, while monitoring crop growth, input distribution and yield prediction.',
      'The Vice Chancellor of University of Uyo, Professor Nyaudo Ndaeyo who spoke during the SAPRUL stakeholders’ meeting urged youths to understand the largely untapped pool of prospects in agriculture beyond the hoe, describing Agriculture as a reservoir that does not only feed the masses but creates wealth and employment along the whole value chain both on farm and off-farm.',
      'The VC who also described women empowerment as key factors that influence outcomes on staple food production, added that women are more likely to be better managers of resources than men, stating that women deserve better attention, support to their associations and groups and equal access to both land and financial resources, reasons he suppose SAPRuL project is targeting 50 percent of women during the project implementation.',
      'During the Meeting, the President of Women in Agriculture, Cross River State, Barr. Theresa Mogbor appreciated El-kanis and Partners for giving opportunity for women to expand their farm yield.',
      "The stakeholders' meeting is really a blessing to me and I am very happy that I came. There are certain challenges we have which have been highlighted. Elkanis and Partners have given us hope that they will do everything possible to increase our farm yield.",
      'My farm used to give me 2.5 tons a year , now I have been assured that I can get up to 4 tons because of the improved seedlings and technological innovations El-kanis is making availble to us. This stakeholders meeting has made us to know that women are very important. With 50 percent slot to women, I am very happy and as President of Women in Agriculture, Cross River State, I will carry out sensitisation on this and ensure that what we intensify more sport for the project.',
    ],
  },
  {
    id: uuidv4(),
    day: '24',
    month: 'May',
    year: '2022',
    title: 'SHUTTING THE DOOR ON MALNUTRITION IN NIGERIA',
    img: malnutrition,
    content: [
      'Malnutrition is common in Nigeria. It most times results to health effects that range from mild to severe, and sometimes life-threatening. The most common malnutrition include deficiencies in vitamin A, iron and zinc with prevalence of 29.5%, 26%, and 20% respectively in children under five years.',
      'Commonly used interventions include nutrient supplementation, dietary diversification, commercial fortification, nutritional education and agricultural interventions. Some of these programmes are expensive with short term benefits. Biofortification of staples has been identified as a cost effective, sustainable means of delivery of nutrition to the population. Biofortified crops formally released in Nigeria are provitamin A cassava, orange flesh sweet potato and yellow maize as well as quality protein maize.',
      'The underlying causes of malnutrition in Nigeria are poverty, inadequate food production, and inadequate food intake. There is a close nexus between malnutrition and poverty. “One of the consequences of poverty,” said, Dr Blessing Akombi-Inyang, a lecturer in Global Public Health at the University of South Wales, “is the lack of access to nutritious food, which predisposes people to poor nutrition. Poverty increases the chances of malnutrition. Malnutrition, in turn, traps communities in poverty. Poverty and malnutrition are inextricably linked.',
      'A well-diversified diet is nature’s own way of meeting the micronutrient needs of a population. However this might be currently unattainable in most households due to poverty and lack of education. Some farmers’ families sell their micronutrient rich harvested fruits and vegetables to buy cheaper staples.',
      'Whilst Nigeria is the biggest global producer of cassava, at 60 mt in 2017, just 2.8% is biofortified. Switching to newly released biofortified varieties of cassava could provide up to 40% of the Vitamin A recommended daily allowance for children under five, in a country where nearly one in three children under five and one-quarter of all pregnant women are Vitamin A deficient. Lack of sufficient vitamin A blinds up to 500,000 children annually and increases the risk of death from disease (such as diarrhea in children). Vitamin A deficiency is widely prevalent in Nigeria.',
      'Foods that are good sources of vitamin A, such as orange fruits, dark leafy vegetables, or meat, are not always available, or may be too expensive in some states. In many States, people eat large amounts of staple foods like cassava or maize. For example, in Akwa Ibom, people eat up to a pound of white garri daily. However, this white garri provides no beta-carotene. Switching to vitamin A cassava, which is rich in beta-carotene, could potentially provide cassava-dependent populations with up to half their daily vitamin A needs.',
      'As part of our effort to minimize micronutrient deficiency, El-kanis and Partners has cultivated orange fleshed sweet potato on over 50 hectares to supply government school feeding programme and is also collaborating with non-governmental agencies such as get Global Alliance for Improved Nutrition (GAIN) and HarvestPlus to strengthen Vitamin A Maize and Vitamin A cassava value chain through training, input supply, technical support and market access. It is projected that biofortification of local staples will provide at least 30% of estimated average requirements (EAR) for iron, 25% for zinc and 50% for provitamin A.',
      '<h3>How to Improve Nutrition in Nigeria</h3>',
      '<b>1. Harness Vulnurable Group Fund (VGF) to support Nutrition-Sensitive Agricultural Programs</b>',
      'The Vulnerable Group Fund is expected to be deducted from call charges from June 2022, which would be set aside to pay for healthcare services for vulnerable Nigerians which include children under five, pregnant women, aged, physically, and mentally challenged persons, and indigent people as may be defined from time to time who cannot pay for health insurance. Part of VGF should be channeled to support farmers grow nutrition-sensitive agriculture in Nigeria. El-kanis and Partners is already implementing nutrition-sensitive agricultural projects in several parts of Nigeria and are open to partnership for scale.',
      'Nutrition-sensitive agriculture is a food-based approach to agricultural development that puts nutritionally rich foods and dietary diversity at the heart of overcoming under-nutrition, over-nutrition and micronutrient deficiencies. The primary objective of investing in nutrition-sensitive agriculture and food systems is to ensure that acceptable, diverse, nutritious and safe foods, adequate to meet the dietary needs of people of all ages, are available and affordable at all times.',
      'This can mean, for example, introducing good practices that enable year-round access to a variety of nutritious food – either by making sure producers have the resources to produce the right foods for a healthy diet, or by equipping markets to sell a variety of nutritious foods at affordable prices. It also means preventing food loss and waste by reducing food-borne pathogens through good hygiene practices and improving technology along the value chain. Nutrition-sensitive agriculture also means educating families about nutrition so they can produce, purchase, prepare and consume healthy foods.',
      '<b>2. Stimulate downstream demand</b>',
      'Urban and rural consumer groups have all demonstrated in-principle willingness to pay for Vitamin A cassava, as high as a 31% price premium for Vitamin A garri when aware of benefits. These groups represent more than 60% of the market (46.8% consuming gari and 14.4% fufu), but the majority are not yet aware of Vitamin A cassava’s nutritional benefits. Stimulating market demand through awareness campaigns will encourage upscale by strategic enterprises and opportunistic smallholders, as uncertainty about market demand will be removed for micro-processors and SMEs.',
      '<b>3. Reinforce linkages between stem producers and small farmers</b>',
      'Once demand is proven, sustained production of Vitamin A cassava, orange flesh sweet potato and vitamin A maize could be addressed through improved linkages between stem producers and farmers as this affects the whole value chain. The demand of basic gari, fufu, custard and other biofortified food products are mainly serviced by smallholder farmers, who get their seeds from large farms undertaking multiplication. As such, the weak linkages between the International agencies, partners, smallholders and the large farms who supply their seeds will need to be strengthened. There are a number of ways development agencies and government can support these value chain linkages, including:',
      'a). Outreach: Supporting farmer outreach to broaden connections between smallholder farmers, agro dealers and stem producers through marketing and information campaigns. This would require a short-term investment and would need to be backed by seed availability.',
      'b). Extension Services: Reinforcing government networks of extension services and demonstration plots is another avenue to connect farmers to services. However, the limited use and effectiveness of these services in Nigeria may restrict the efficacy of this approach.',
      'c). Clustering: Facilitating clustering of smallholder farmers in associations or cooperatives in order to create direct links between cooperatives and processors. The high fragmentation of both smallholder farmers and microprocessors makes this both a potentially high impact and challenging approach We believe that the most effective intervention would be outreach to farmers in order to establish seed links, and clustering of farmers to facilitate downstream linkages to processors. Much of this has already been conducted by HarvestPlus programmes, hence we would recommend the scale-up of these activities into areas beyond south west of Nigeria.',
    ],
  },
  {
    id: uuidv4(),
    day: '03',
    month: 'May',
    year: '2022',
    title: 'CONTENDING THE ESCALATING FOOD CRISIS IN NIGERIA',
    img: crisis,
    content: [
      'Farmers in Nigeria have raised warning alarm over possible rise in prices of food due to rising cost of fertilisers which has more than doubled.',
      'The warning came on the heels of hike in the price of a 50kg bag of NPK, a critical type of fertiliser mainly used by smallholder farmers, has surged by 181.3 percent to N22,500 from N8,000 which was sold last year.',
      'There is a global surge in fertiliser prices caused by the Russia-Ukraine crisis that has cut off shipments from the Black Sea region, which accounts for 30 percent of the major grain trade and at least 12 percent of food calories traded.',
      'Rising cost of food production, diesel price hike and high labour cost will contribute to Nigeria’s food insecurity” said Moses Akpan, retired director from Ministry of Agriculture and current Executive Director, El-kanis and Partners.',
      '"Last year, the production cost for rice per hectare was N415,000.00 and presently, 1 hectare of rice cultivation will go for N680,000 on the average. Nigeria would experience surge in food prices which will inadvertently affect the middle and lower class who constitute a greater percentage of Nigeria\'s population.” he added.',
      'An increase in food prices will further compound the poor nutrition level in the country. Nigeria’s core inflation accelerated to 15.9 percent in March from 15.7 in February on rising energy costs.',
      'While Africa’s most populous country is rich in urea, it imports 34 percent of the raw materials needed to produce NPK.',
      'The Russia-Ukraine war had trapped four inbound vessels containing over 70,000 metric tons of potash – a key raw material input for producing NPK, putting food security in Nigeria at risk.',
      'According to the official African Development Bank (AfDB) Report, the price of wheat in Africa has increased by more than 45% since the war began. Fertilizer costs have risen by 300%, and the continent is facing a 2 million metric ton fertilizer shortfall. If this isn’t fixed, AfDB said, Africa’s food output will drop by 20%, costing the continent approximately $11 billion in lost revenue.',
      'A leading indigenous Agricultural firm, El-kanis and Partners that are into food production and agricultural support service is ready and prepared to contend this monstrous food scarcity which is imminently threatening the livelihoods of over 100 million Nigerians.',
      'To curb food insecurity in Nigeria, El-kanis and Partners have commenced cultivation of Rice, cassava, maize the 3 major staple foods in Nigeria. Leveraging on several factors to bridge the hunger gap such as availability of nutrient enriched land and host community support, El-kanis will be cultivating over 7,000h of arable land to feed local mills and local foodstuff markets.',
      'As indicators for the likelihood in the food crises deepen, it is imperative for citizens to heed this warning and begin an aggressive plan of going into full scale farming to ameliorate the crises which looms.',
      'Other measures that could curb food crises include elevating the value of women in society and providing them with the education and resources they need to manage their farms, their families and their health. Nigeria should create conditions that will allow women to grow more food, feed a greater number of people and share their knowledge with others. El-kanis in partnership with West Africa Trade and Investments Hub and USAID are co-investing to support over 4,000 women farmers who will cultivate rice and maize.',
      'Also, providing resources directly to farmers is one of the fastest ways to improve their conditions and crop yields, as well as the health and nutrition of their families and communities. And providing resources to farmers doesn’t just help build individual and community food security — it can help alleviate poverty, too, since extreme poverty and hunger has a cyclical relationship.',
      'Hungry people have a hard time working, and people who can’t work have a hard time affording food. Nigeria needs to prioritise agriculture as an economic and security agenda. Nigerian leaders must prioritise investment in agriculture and food systems, not only as an economic agenda, but as a security agenda. Investment in agriculture has been found to be 11 times more effective in reducing poverty than any other sectors in Nigeria.',
      'Additionally, if Nigeria prioritises investment in youth, it could reap additional US$500 billion in return to its economy every year.',
      'Making agriculture profitable and attractive to young people and involving young people in the planning, design, and implementation of agricultural policies could also curb rising food cost.',
      'El-kanis has observed low involvement of youth in policy dialogues, budget consultation process, and programme implementation. As a result, policies often fail to provide them with adequate support that aligns with their aspirations. To attract young people into the sector, government spending must be targeted at reducing real and perceived agribusiness risks, enable financing, and improve infrastructure. Currently, high lending rates are stunting the growth of small and medium scale agribusinesses. Rural agribusinesses can thrive when they have access to finances and are well connected to local communities and markets.',
      'Nigerian leaders must take leadership and prioritise the implementation of Malabo commitments on Accelerated Agricultural Growth and Transformation for Shared Prosperity and Improved Livelihood. The Biennial Review (BR) scorecard is an important tool for learning and monitoring our collective progress. Often times, several policies are developed, but not fully implemented. For Nigeria to make significant progress, each state must report its progress and respond swiftly to its policy formulation and implementation gaps.',
      'Nigeria now has the tools to reach SDG 2’s goal of zero hunger by 2030: our youth and arable land. However, our leaders must prioritise the three steps in order for Nigeria to be well on the way to tackling food insecurity, while also creating jobs for our teeming youth.',
      'In addition, The African Development Bank ( AfDB) has designed a $1.5 billion emergency food production plan for Nigeria and Africa. The African Development Bank’s emergency food plan is currently before the Bank’s Board of Directors for approval. It is set for implementation. AfDB also noted that Africa now faces a shortage of at least 30 million metric tons of food, especially for wheat, maize, and soybeans imported from both countries.',
      'African Development Bank has designed a $1.5 billion Africa Emergency Food Production Plan to support countries to rapidly-produce around 38 million tonnes of food to mitigate the effect of the war on food prices. This will include 11 million tonnes of wheat; 18 million tonnes of maize; 6 million tonnes of rice; and 2.5 million tonnes of soybean. Nigeria is a top priority under the emergency food production plan, Adesina said, adding that the strategy will help the country produce 9.5 million tonnes of food.',
      'The initiative will support the country’s 5 million smallholder farmers during the wet season of 2022 and 1 million across 10 Northern states during the dry season of 2022/2023.',
    ],
  },
  {
    id: uuidv4(),
    day: '12',
    month: 'Apr',
    year: '2022',
    title: 'EL-KANIS, HARVESTPLUS, GAIN DEVELOP BROCHURE FOR VAC/VAM PRODUCTS LABELLING IN NIGERIA',
    img: vac,
    content: [
      'As part of the overall effort to minimize micronutrient deficiency in Nigeria, a leading Agribusiness service, El-kanis and Partners has designed and developed brochure aimed at providing food producers and manufacturers with knowledge on how to procure, store, process, sell and market Vitamin A Maize (VAM) and Vitamin A Cassava (VAC) food products.',
      'The project, sponsored by HarvestPlus and Global Alliance for Improved Nutrition (GAIN) through the Commercialization of Biofortified Crops (CBC) project is targeted at  providing food producers and manufacturers with knowledge on how to procure, store, process, sell and market VAM and VAC food products.',
      'Speaking during the stakeholders review session, the Managing Director El-kanis and Partners, Mr. Ben Ukpe noted that the main objective of the brochure is to establish the current regulatory frameworks and food industry guidelines on the marketing of VAC and VAM ingredients.',
      'El-kanis provided HarvestPlus and GAIN with the expertise to develop, implement, and maintain modern food safety management systems based on Hazard Analysis Critical Control Point (HACCP) system principles.',
      '“This is to create guideline documents for businesses involved in production, processing and marketing of VAC and VAM, which we have also identified authorities involved in the creation and endorsement of new standards and regulations in Nigeria.” Ukpe said.',
      'Mr. Ukpe decried a situation where most consumers make purchases without taking note of this information perhaps because they do not understand the information provided due to the complexity of the data.',
      'He revealed that as at the time of developing the document, Nigeria had no laws regulating products of Vitamin A cassava and Vitamin A Maize and also acknowledged draft biosafety bill  before the National Assembly for consideration.',
      '“The draft bill advocates compulsory labeling of food items containing products of biotechnology to protect consumers’ right to know. When the bill is passed, NAFDAC will be the enforcing agency.” Mr. Ukpe said.',
      'On the purpose of the brochure, the MD of El-kanis and Partners said it will ensure that VAC/VAM food labeling and marketing is effective in Nigeria, provide food processors and producers with means for conveying information of the nutrient content of enriched foods on the label; encourage the use of sound nutrition principles in the formulation of VAC/VAM foods which would benefit public health as well as provide opportunity to include VAC/VAM nutrition information on the label.',
      'He also explained that the brochure will ensure that Vitamin A Maize (VAM),Vitamin A Cassava (VAC) VAC/VAM food labeling does not describe a product or present information about it that is in any way false, misleading, deceptive, or insignificant in any manner and to ensure that no nutrition claim is made without nutrition labelling.',
      'In his response, president Consumer Advocacy for Food Safety and Nutrition Initiative(CAFSANI) , Professor Olugbenga Ogunmoyela commended the brochure and enjoined processors to explore the rich content of the brochure.',
      'He also asked HarvestPlus to make the hardcopy available at ADP and CBC desk offices for easy accessibility.',
      'Speaking, Goodluck Ogu, Country Manager, Commercialization of Biofortified Crops Program noted that to assist  consumers put Vitamin A cassava and Vitamin A Maize information into consideration in their purchase, prior knowledge is required, adding that such knowledge could enhance implementation of the understood and remembered information in making a food choice.',
      '“One way through which this information can be accessed is food labeling. This can assist in curbing health risks by helping people make better food purchase decisions and adopt healthier eating patterns.” Mr. Ogu said.',
      'In her presentation, Jenny Walton, Demand Creation, HarvestPlus Washington revealed that information about nutrients enriched foods will ensures that the health information gap between nutrients enriched food producers and nutrients enriched food consumers is bridged which she said will further enable consumers make well-informed eating choices.',
      'Meanwhile, the brochure developed by El-kanis and Partners has different labeling and information strategies employed to enhance better food purchasing choices.',
      'The brochure includes provision of nutrient content information using either back-of-pack product labels or diagrams to indicate important nutrients and enumeration of ingredients and its quantities on restaurant menus.',
      'El-kanis food safety and development programmes has provided high-quality professional services to help more than 20 companies including Small and Medium Scale Enterprises (SMSE) apply national food safety standards and adapt sustainable business models, in which one of the businesses received 2020 National SMSE Award from the Vice President of the Federal Republic of Nigeria. EL-kanis programs include food safety assessments, outgrower projects, agribusiness training and input supplies.',
      'El-kanis worked with Federal Ministry of Agriculture and Rural Development,NUJ, Tertiary institutions, NAFDAC and other sister regulatory agencies during the development of the brochure.',
    ],
  },
  {
    id: uuidv4(),
    day: '03',
    month: 'Apr',
    year: '2022',
    title: 'INSIGHTS FROM OUR CONCLUDED TRAINING ON VEGETABLE PRODUCTION',
    img: veg,
    content: [
      'The three day free training on Vegetable Farming with an objective to build the capacity of vegetable farmers and intending farmers was completed on Friday, 1st April 2022. The aim is to improve the livelihoods of low-income vegetable and fruit farmers in Akwa Ibom State. The project targets the horticulture subsector (vegetables and fruits), which is particularly relevant to women farmers. The importance of training women farmers cannot be over-emphasized.',
      'A total of 48 participants participated in this training. Women generally have been in the forefront of the fight against food crisis in the entire world. In Africa and Nigeria in particular, women produce about 78 percent of the continent’s food, vegetables including meat from livestock on subsistence and small land holdings with very limited access to training and other production resources Rural women are engaged in a wide range of farming activities and are responsible in most cases for food production, processing, distribution, home consumption and for sale. Women now make up the majority of the agricultural sector in Nigeria, but recent evidence suggests that their productivity is constrained by lack of appropriate training skills.',
      'The purpose of this training was to train participants on Good Agronomic Practice and its role in the viable vegetable production and also provide information for those intending to start Backyard vegetable farm. Training included various topics such Pest/disease control method, Fertilizers selection and application rate, Storage methods & procedures , Harvesting time & techniques, Nursery operations, Planting and Transplanting operations, Chemical weed control, Compost manure preparation, Land Management, Agric-support services and Irrigation methods.',
      'The local demand for vegetables is expected to increase dramatically in the coming years. Two major trends will bring this about - rising per capita income and continuing population growth in the Nigeria. Vegetables supply vitamins, minerals, fibers, and supplementary amounts of protein and calories essential to the human diet. If the urban and rural poor in Nigeria were to have access to vegetables, there should be adequate and stable supplies to keep their prices affordable. No less significant justification for trying to increase vegetable productivity in Nigeria is the potential to generate high income for small producers and additional employment for landless workers and for those involved in food processing.',
      '<h3>The Benefits of Vegetable Farming</h3>',
      "a). Consuming more fresh fruits and vegetables is one of the most important things you can do to stay healthy. When you pick vegetables right from your garden, the vitamin content will be at its highest. Also, you are reducing the risk of eating vegetables that contain harmful chemicals–you know exactly what you're eating!",
      'b). Save money on groceries. One of the benefits of enjoying garden vegetables is a reduced monthly food bill. You can grow organic vegetables for a fraction of the cost in the stores.',
      'c). Get outdoor exercise. Gardening is a physical activity and pulling weeds, planting, and digging can burn up to 400 calories per hour. Gardening is also a good mental exercise and helps keep your mind sharp.',
      'd). Gardening is a natural stress reliever. Being outside in the fresh air and sunshine can improve mood and make you feel rejuvenated and overall happy. Growing your own produce also gives you a great sense of accomplishment.',
      'Our subsequent blog will share more on what we trained farmers. Watch out!!',
    ],
  },
  {
    id: uuidv4(),
    day: '11',
    month: 'Mar',
    year: '2022',
    title: 'SILICON VALLEY FOR AFRICA’S AGRICULTURAL START-UPS',
    img: silicon,
    content: [
      'The agricultural and food sector holds the potential to become the driving force behind Africa’s economic development. In order to harness this potential, innovations are needed that can kick-start production and productivity in the agricultural sector, multiply value added and thus generate both jobs and income. In Africa, it is digital innovations in particular that drive agriculture, which is currently still often performed at subsistence level. Despite steadily growing venture capital investments in the continent, start-ups frequently lack capital in their early stages of development. However, this is an important basic prerequisite for taking businesses and innovations to scale. Acquiring sufficient capital is difficult for many young start-ups, with 80 per cent of them already failing after the launch of their first product.',
      'It is in this potential “valley of death” where the project “Scaling digital agriculture innovations through start-ups” (SAIS) sets in. With tailored company development measures implemented by international venture builders, the investment readiness of the start-ups – i.e. their capacity to understand and meet the specific needs and expectations of investors – is improved. This raises the prospects of success for the start-ups being able to obtain the capital needed for scaling or to tap further markets with the aid of new business partners. Here, SAIS adapts processes of existing innovation systems and appropriately applies them to the African context.',
      'By supporting a targeted 30+ start-ups, digital innovations are to directly reach the true target group of the project, the rural population linked to agriculture and food value chains. By the end of the project period, at least 100,000 additional users are to have been gained and earned more income. In this manner, SAIS contributes to Sustainable Development Goals (1) reducing poverty, (2) combating hunger, (8) decent employment and economic growth and (9) industry, innovation and infrastructur.',
      'The project comprises three action areas and covers a term of five years (2019–2023). The German Federal Ministry for Economic Cooperation and Development (BMZ) has commissioned Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ) to implement SAIS.',
      'Crossing the valley of death in three steps',
      '- Step 1: Choosing start-ups: The initial step is to identify, assess and select start-ups in Africa. The start-ups are chosen according to a catalogue of criteria in a selection process comprising several phases. Start-ups are eligible if they are in the post-seed or second stage: they have already successfully set up a business, introduced a digital innovation on the market and attracted their first customers. In addition, with regard to the size of the market and the target group, they have to bear a high scaling potential and generate a positive income effect among their users. In its second year of implementation, SAIS has already recorded more than 250 applications from founders, representing a plus of just below 20 per cent, which speaks for a good standing of the project in Africa’s start-up ecosystem.',
      '- Step 2: Investment Readiness Programme: In the second step, which forms the core of SAIS, the start-ups join a nine-month Investment Readiness Programme (IRP) with tailored business development measures.',
      'First of all, needs assessments of the individual start-ups are made. Their potential for optimisation is defined, and individual development plans are drawn up with the founders. Building on this, the start-ups are then provided with advice on the further development of their business. Here, depending on respective needs, topics such as strategic development, specifying the business model, improving marketing, finance management, acquisition of new groups of customers or technology consulting are addressed. In addition, the pitch decks – brief presentations of the start-ups for potential investors – are optimised, customs in communicating with investors are imparted, and basic aspects of business appraisal are explained. In 2021, 16 start-ups are being supported in this manner.',
      '- Step 3: Networking: The third step involves networking the start-ups with potential business partners and investors. In order to better integrate the selected start-ups in their local ecosystems before and during company development, SAIS is working with a network of local innovation hubs.',
      'This network forms an important basis for the recruitment of investors and the formation of strategic partnerships. These may be both financiers such as business angels, venture capital funds or impact investors and business partners or development cooperation projects. Initial contacts are already established during the company development phase. In a check-in after the first half of the programme, the start-ups are acquainted with potential investors and business partners and informed about further financing options. On the Demo-Day at the end of the IRP, the contacts with investors and potential business partners are intensified with the aim of establishing concrete partnerships and business relationships. Especially promising start-ups can join the SAIS Masterclass, which addresses matchmaking with potential investors and partners in detail.',
      'Outlook',
      'The SAIS project has established itself as an important partner for Ag- and FoodTech start-ups in African ecosystems. Africa’s start-up landscape is clearly heading for growth.',
      'Young people are seeking solutions to existing challenges with technology, with the desire to contribute to the continent’s economic development. Despite strong growth in the AgTech sector, many developments are still in their infancy. In particular, available investment in the field of venture capital is still at a comparatively low level, complicating scaling for start-ups with good ideas and good teams. Less than one per cent of venture capital world-wide (at approximately 220 billion US dollars in 2019) is currently invested in Africa.',
      'While investments roughly double from year to year, they are still too low for the challenges and opportunities which the continent faces, leaving valuable potential lying fallow. SAIS seeks to generate additional capital options for African start-ups through matchmaking and to also make the market attractive for investors from Germany and Europe. Here, the SAIS team have opted for various channels such as business clubs, investor data banks and contacts they have established themselves as well as renowned foundations.Despite existing challenges such as the “valley of death”, a paucity of investment and the corona pandemic, African start-ups and SAIS are optimistic about the future.',
      'This is why SAIS has started to focus also on the Francophone region, where it seeks to raise start-up potentials and offer tailored support measures for start-ups in the programme. Furthermore, SAIS will extend its efforts in the promotion of female founders with awards tailored to female-led start-ups and increased attention on network building for female founders throughout the programme.',
      'SAIS start-ups in the corona crisis',
      'The corona pandemic is causing considerable problems for African agriculture since central markets have shut down and travelling to the commercial centres is hardly possible. As a consequence, farmers are losing income, while food supplies for the population are becoming tight.',
      'Some of the African start-ups supported by SAIS are addressing these challenges with digital solutions. With the aid of the Ugandan platform Bringo Fresh, farmers can sell their goods in spite of restrictions. Customers need not go to the markets but can order food directly via the app. Orders are freshly packed and immediately delivered by moped. During the corona crisis, Bringo Fresh has succeeded in more than doubling its turnover compared to the previous year. In contrast, the Zambian company eMsika is a virtual marketplace for agricultural input. Seed, fertiliser and state-of-the-art technologies such as solar-powered water pumps can be ordered online and delivered to remote areas. Around 2,100 Zambian farmers are being provided with input by eMsika.',
      'Both start-ups are already planning their next steps. Bringo Fresh is working on its expansion to Kenya, and eMsika is developing an online academy in which farmers are trained in improved cultivation methods.',
      '<b>Start-up application areas suitable for support:</b>',
      '1. E-Commerce for farmers (e. g. selling produce, inputs)',
      '2. Information & consulting services (know-how, consulting, weather data)',
      '3. Fintech for farmers (financing, credit services, crowd funding)',
      '4. Farm supply chain management (farm management, transport, warehousing)',
      '5. New technologies (Internet of things, artificial intelligence, etc.)',
    ],
  },
];

export default blogData;
